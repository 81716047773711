import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import water from '../images/water.png'
import sugar from '../images/sugar.png'
import paper from '../images/paper.png'
import barrel from '../images/barrel.png'
import molecule from '../images/molecule.png'
import ecology from '../images/ecology.png'
import service from '../images/service-excellence.png'
import delivery from '../images/swift-delivery-time.png'
import quality from '../images/quality.png'
import map from '../images/map.png'
import moment from 'moment'

const IndexPageEn = () => (
  <Layout>
    <div className="cta">
      <div className="bookmark"></div>
      <div className="columns cta-content">
        <div className="column">
          <h1 className="title title-middle cta-content-title">
            IN-ECO is a chemical reagents brand
          </h1>
          <h2 className="subtitle cta-content-subtitle">
            Our reagents implement industrial chemical treatment programs
          </h2>
          <Link to="/about-us" className="button button-primary">
            Get to know
          </Link>
        </div>
        <div className="column has-text-right">
          <div className="title cta-years">
            {moment().diff(moment([2009, 4, 13]), 'years')}
          </div>
          <div className="subtitle cta-content-subtitle subtitle-small">
            Years of successful delivery of
            <br /> client-oriented business results
          </div>
        </div>
      </div>
    </div>
    <div className="columns cta-categories">
      <Link to="/water-treatment" className="column cta-categories-item">
        <div className="cta-categories-item-img-container">
          <img src={water} className="cta-categories-item-img" alt="" />
        </div>
        <span className="cta-categories-item-text">
          Water treatment <br /> chemicals
        </span>
      </Link>
      <Link
        to="/sugar-processing-chemicals"
        className="column cta-categories-item"
      >
        <div className="cta-categories-item-img-container">
          <img src={sugar} className="cta-categories-item-img" alt="" />
        </div>
        <span className="cta-categories-item-text">
          Reagents for <br /> sugar processing
        </span>
      </Link>
      <Link
        to="/paper-processing-chemicals"
        className="column cta-categories-item"
      >
        <div className="cta-categories-item-img-container">
          <img src={paper} className="cta-categories-item-img" alt="" />
        </div>
        <span className="cta-categories-item-text">
          Reagents for <br /> paper processing
        </span>
      </Link>
      <Link to="/oil-field-chemicals" className="column cta-categories-item">
        <div className="cta-categories-item-img-container">
          <img src={barrel} className="cta-categories-item-img" alt="" />
        </div>
        <span className="cta-categories-item-text">
          Reagents for oil
          <br />
          and gas processing
        </span>
      </Link>
      <Link to="/purolite" className="column cta-categories-item">
        <div className="cta-categories-item-img-container">
          <img src={molecule} className="cta-categories-item-img" alt="" />
        </div>
        <span className="cta-categories-item-text">Purolite resins</span>
      </Link>
    </div>
    <div className="advantages">
      <h2 className="title title-large advantages-title">
        IN-<span className="text-accented">ECO</span> solutions
      </h2>
      <div className="columns advantages-item">
        <div className="column">
          <div className="content-text advantages-item-text">
            IN-ECO products promote environmental sustainability and cleaner
            production line in industrial manufacturing
          </div>
          <div className="content-text advantages-item-text">
            Our solutions provide an increase in equipment efficiency that
            reduces energy consumption and environmental waste
          </div>
        </div>
        <div className="column">
          <div className="advantages-item-image-container">
            <div className="advantages-item-title">Ecology</div>
            <img src={ecology} className="advantages-item-image" alt="" />
          </div>
        </div>
      </div>
      <div className="columns advantages-item">
        <div className="column">
          <div className="content-text advantages-item-text">
            People behind the company are experts in the field with demonstrated
            success in delivering chemical treatment solutions for more than 25
            years
          </div>
          <div className="content-text advantages-item-text">
            Versatile experience of IN-ECO experts ensures that each client gets
            a tailored solution
          </div>
        </div>
        <div className="column">
          <div className="advantages-item-image-container">
            <div className="advantages-item-title">Service excellence</div>
            <img src={service} className="advantages-item-image" alt="" />
          </div>
        </div>
      </div>
      <div className="columns advantages-item">
        <div className="column">
          <div className="content-text advantages-item-text">
            The chemicals of IN-ECO undergo continuous testing starting from raw
            materials to the end products
          </div>
          <div className="content-text advantages-item-text">
            This ensures the high quality standard for the in-eco reagents that
            are certified by ISO 9001 and ISO 14001 certificates
          </div>
        </div>
        <div className="column">
          <div className="advantages-item-image-container">
            <div className="advantages-item-title">Quality</div>
            <img src={quality} className="advantages-item-image" alt="" />
          </div>
        </div>
      </div>
      <div className="columns advantages-item">
        <div className="column">
          <div className="content-text advantages-item-text">
            Convenient location in the proximity of the geographical Center of
            Europe alongside with the proven track record of fast execution
            ensures the swift delivery of our products
          </div>
        </div>
        <div className="column">
          <div className="advantages-item-image-container">
            <div className="advantages-item-title">Swift delivery time</div>
            <img src={delivery} className="advantages-item-image" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div className="location">
      <img src={map} alt="" />
      <h4 className="location-title">
        Our<span className="text-accented"> European </span>location
      </h4>
    </div>
  </Layout>
)
export default IndexPageEn
